<template>
  <div class="py-50">
    <b-tabs id="menu" class="mb-2">
      <b-tab title="Models">
        <h5 class="ml-1 mt-2">
          My Models
        </h5>
        <hr>
        <model-table v-if="userModels.length > 0" :table-data="userModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Models
        </h5>
        <hr>
        <model-table v-if="sharedModels.length > 0" :table-data="sharedModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No models shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Models
        </h5>
        <hr>
        <model-table v-if="publicModels.length > 0" :table-data="publicModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public models found.</span>
        </div>
      </b-tab>

      <!-- Model Snapshots Tab -->
      <b-tab title="Snapshots">
        <h5 class="ml-1 mt-2">
          My Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="tempUserModels.length > 0" id="model-snapshots-table" :table-data="tempUserModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots owned by the current user.</span>
        </div>

        <h5 class="ml-1">
          Shared Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="tempSharedModels.length > 0" :table-data="tempSharedModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No snapshots shared with the current user.</span>
        </div>

        <h5 class="ml-1">
          Public Snapshots
        </h5>
        <hr>
        <temp-model-table v-if="tempPublicModels.length > 0" :table-data="tempPublicModels" />
        <div v-else class="mb-4 ml-1">
          <span class="text-muted">No public snapshots found.</span>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import ModelTable from '@/views/Home/ModelTable.vue'
import TempModelTable from '@/views/Home/TempModelTable.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'ModelsBrowser',
  components: {
    ModelTable,
    TempModelTable,
  },
  setup() {
    const userModels = ref([])
    const getUserModels = () => {
      axiosIns
        .get('/api/v2/models/user')
        .then(({ data }) => { userModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getUserModels] ${e}`))
    }
    getUserModels()

    const sharedModels = ref([])
    const getSharedModels = () => {
      axiosIns
        .get('/api/v2/models/shared')
        .then(({ data }) => { sharedModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getSharedModels] ${e}`))
    }
    getSharedModels()

    const publicModels = ref([])
    const getPublicModels = () => {
      axiosIns
        .get('/api/v2/models/public')
        .then(({ data }) => { publicModels.value = data })
        .catch(e => console.error(`[ModelsBrowser::getPublicModels] ${e}`))
    }
    getPublicModels()

    const allTempModels = ref([])
    const tempPublicModels = ref([])
    const tempSharedModels = ref([])
    const tempUserModels = ref([])

    const getTempModels = () => {
      coreService
        .get('/v1/model/temp')
        .then(({ data }) => {
          allTempModels.value = data.all_models
          tempPublicModels.value = data.public_models
          tempSharedModels.value = data.shared_models
          tempUserModels.value = data.user_models
        })
        .catch(e => console.error(e))
    }
    getTempModels()

    const getAllModels = () => {
      getTempModels()
      getPublicModels()
      getSharedModels()
      getUserModels()
    }

    return {
      userModels,
      sharedModels,
      publicModels,
      allTempModels,
      tempPublicModels,
      tempSharedModels,
      tempUserModels,
      getTempModels,
      getAllModels,
    }
  },
}
</script>
