<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      fixed
      hover
      :fields="fields"
      class="mb-50"
      @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
    >
      <!-- model-name -->
      <template #cell(model)="data">
        <div
          class="font-weight-bold cursor-pointer"
          @click="data.toggleDetails"
        >
          {{ data.item.name }} <span class="font-small-2">({{ data.item.key }})</span>
        </div>
      </template>

      <template #row-details="data">
        <div class="row-details p-2">
          <div>
            <h5>Snapshot Imported</h5>
            <p class="ml-1 text-nowrap select-all">
              {{ data.item.snapshot_imported | informalDate }}
              by
              {{ data.item.snapshot_imported_by ? data.item.snapshot_imported_by : '-' }}
            </p>
          </div>
          <div>
            <h5>UUID</h5>
            <p class="ml-1 text-nowrap select-all">
              {{ data.item.id }}
            </p>
          </div>
          <div>
            <h5>Classification</h5>
            <p class="ml-1">
              {{ data.item.default_security_classification ? data.item.default_security_classification : '-' }}
            </p>
          </div>
          <div>
            <h5>Description</h5>
            <p class="ml-1">
              {{ data.item.description ? data.item.description : '-' }}
            </p>
          </div>
          <div>
            <h5>Scope</h5>
            <p class="ml-1">
              {{ data.item.scope ? data.item.scope : '-' }}
            </p>
          </div>
          <div>
            <h5>Created</h5>
            <p class="ml-1">
              {{ data.item.created | formatDate }}
            </p>
          </div>
        </div>
      </template>

      <!-- date-created -->
      <template #cell(date)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.created | diffForHumans }}</span>
          <span class="font-small-2 text-muted text-nowrap">
            {{ data.item.created | formatDate }}
          </span>
        </div>
      </template>

      <!-- last-opened -->
      <template #cell(lastEdit)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.date_last_opened | diffForHumans }}</span>
          <span class="font-small-2 text-muted text-nowrap">
            On {{ (data.item.date_last_opened).split('T')[0] }},
            at {{ (data.item.date_last_opened).split('T')[1] }}
          </span>
        </div>
      </template>

      <!-- row-actions -->
      <template #cell(actions)="data">
        <div v-if="!data.importing">
          <div class="d-inline-flex w-100 justify-content-center">
            <b-button-group size="sm">
              <b-button variant="outline-primary" @click="data.toggleDetails">
                Details
              </b-button>
              <b-button id="btn-open-model-snapshot" variant="outline-success" data-cy="btn-open-workspace" @click="openModelWorkspace(data.item)">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-50 text-success"
                />
                <span>Open</span>
              </b-button>
            </b-button-group>
          </div>
        </div>

        <div v-else>
          <b-button class="mb-1" variant="outline-danger">
            Importing in Progress
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton,
} from 'bootstrap-vue'
import router from '@/router'

export default {
  name: 'TempModelTable',
  components: {
    BCard,
    BTable,
    BButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'model', label: 'Model', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'owner', label: 'Owner', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'date', label: 'Created', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'actions', label: 'Actions', class: 'text-center', thStyle: { width: '30%' },
        },
      ],
      deleteItem: {},
      moveToLiveModel: {},
    }
  },
  methods: {
    openModelWorkspace(model) {
      const modelId = model.id
      this.$store.dispatch('model/openTempWorkspace', model).then(() => {
        router.push({ name: 'model_dashboard', params: { modelId } })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/style.scss';

.col-divider > * + * {
  border-top: 0;
  border-bottom: 0;

  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));

  --tw-divide-opacity: 0.1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  border-style: solid;
}

.row-details {
  @extend .border-rounded-lg;
  background: whitesmoke;
}

.text-ellipsis-10 {
  max-width: 10rem;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.dark-layout {
  .row-details {
    background: rgba(255, 255, 255, 0.05)
  }

  .col-divider > * + * {
    --tw-divide-opacity: 0.1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
}
</style>
